import simg from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'
import simg5 from '../images/service/5.jpg'
import simg6 from '../images/service/6.jpg'

import sIcon1 from '../images/icon/kitchen-utensils.png'
import sIcon2 from '../images/icon/gas-stove.png'
import sIcon3 from '../images/icon/water-tap.png'
import sIcon4 from '../images/icon/sink.png'
import sIcon5 from '../images/icon/basement.png'
import sIcon6 from '../images/icon/remodeling.png'
import sIcon7 from '../images/icon/pipe.png'



import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'



const Services = [
    {
        Id: '1',
        sImg:simg6,
        sIcon:sIcon6,
        sTitle: 'Servis plynových zariadení',
        description:'Oprava, prehliadky, údržba kotlov. Viessmann, Protherm, Attack, Immergas, Biasi, Quadroflex',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        
    },
    {
        Id: '2',
        sImg:simg2,
        sIcon:sIcon2,
        sTitle: 'Montáž kotlov / montáž sanity',
        description:'Plynové, elektrické, tepelné čerpadla',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        sIcon:sIcon3,
        sTitle: 'Vodoinštalácia',
        description:'Čerpadlá, ponorky, rozvody, ohrev vody....',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg6,
        sIcon:sIcon7,
        sTitle: 'Kanalizácia',
        description:'Montáž kanalizácie, Rekonštrukcia rozvodov.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        sImg:simg5,
        sIcon:sIcon5,
        sTitle: 'Podlahové vykurovanie',
        description:'Electronic typesetting rema essentially unchanged was popularised.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        sImg:simg5,
        sIcon:sIcon5,
        sTitle: 'Fotovoltanika, Solárne systémy',
        description:'Fotovoltanické systémy na výrobu elektriny, soláre na ohrev teplej vody',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,

    }
]
export default Services;